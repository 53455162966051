import {
  Link,
} from 'react-router-dom';
import { t } from '@i18n';
import {
  ROOT_ROUTE,
} from '../routes';
import {
  WHATSAPP_NUMBER,
  DEFAULT_WHATSAPP_MESSAGE,
} from '../variables';
import Button from './Button';
import logo from '../assets/logo-white.png';
import whatsappIcon from '../assets/whatsapp-icon-white.svg';

function Header() {
  return (
    <div className="bg-transparent absolute w-full">
      <div className="container py-4 lg:py-6">
        <div className="col-span-full flex items-center justify-between">
          <Link to={ROOT_ROUTE}>
            <img src={logo} alt={t('Components.Header.ALT_LOGO')} className="max-h-10 lg:max-h-12" />
          </Link>
          <div className="flex justify-end items-center">
            <a href="#products" className="hidden lg:inline mr-12">
              <Button type="terciary-white" text={t('Components.Header.LINK_1')} />
            </a>
            <a href="#services" className="hidden lg:inline mr-12">
              <Button type="terciary-white" text={t('Components.Header.LINK_2')} />
            </a>
            <a
              href={`https://wa.me/${WHATSAPP_NUMBER}/?text=${DEFAULT_WHATSAPP_MESSAGE}`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="bg-transparent h-12 p-3 sm:px-6 rounded box-border border-2 border-neutral-lightest flex items-center">
                <span className="hidden sm:inline label-M text-neutral-lightest font-bold mr-2">{t('Components.Header.CALL_TO_ACTION')}</span>
                <img src={whatsappIcon} alt="Ícono Whatsapp" className="w-6" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
