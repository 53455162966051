import PropTypes from 'prop-types';
import * as eva from 'eva-icons';
import { useEffect } from 'react';

function Icon({
  name, size, color, isOutline,
}) {
  const iconName = isOutline ? `${name}-outline` : name;

  useEffect(() => {
    eva.replace();
  }, [iconName, size, color]);

  return (
    <i data-eva={iconName} className={`${size}-icon fill-current text-${color} inline`} />
  );
}

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  isOutline: PropTypes.bool,
};

Icon.defaultProps = {
  name: 'activity',
  size: 'regular',
  color: 'current',
  isOutline: false,
};

export default Icon;
