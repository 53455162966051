import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import Layout from './components/Layout';
import Home from './pages/Home';

import {
  ROOT_ROUTE,
} from './routes';

import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Layout>
          <Route exact path={ROOT_ROUTE} component={Home} />
        </Layout>
      </Switch>
    </Router>
  );
}

export default App;
