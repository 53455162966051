import PropTypes from 'prop-types';
import Icon from './Icon';

function ItemList({ icon, text }) {
  return (
    <p className="mt-4 text-primary-darkest">
      <Icon name={icon} size="small" color="secondary" />
      <span className="ml-2 align-middle">{ text }</span>
    </p>
  );
}

ItemList.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};

ItemList.defaultProps = {
  icon: 'checkmark',
  text: 'Description of item',
};

export default ItemList;
