import PropTypes from 'prop-types';
import { useState } from 'react';

function Input({ placeholder, onChange }) {
  const [value, setValue] = useState('');

  const updateValue = ({ target: { value: inputValue } }) => {
    setValue(inputValue);
    if (onChange) onChange(inputValue);
  };
  return (
    <input
      type="text"
      value={value}
      onChange={updateValue}
      placeholder={placeholder}
      className="transition duration-300 bg-neutral-lightest p-4 w-full border border-neutral-light box-border h-12 focus:border-primary"
    />
  );
}

Input.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  placeholder: 'Enter value',
};

export default Input;
