import Hero from '../components/Hero';
import Categories from '../components/Categories';
import Brands from '../components/Brands';
import Services from '../components/Services';
import Contact from '../components/Contact';

function Home() {
  return (
    <div>
      <Hero />
      <Categories />
      <Brands />
      <Services />
      <Contact />
    </div>
  );
}

export default Home;
