import { t } from '@i18n';
import logoCalaminon from '../assets/logo-calaminon.png';
import logoEternit from '../assets/logo-eternit.png';
import logoImptek from '../assets/logo-imptek.png';
import logoPolyarq from '../assets/logo-polyarq.png';
import logoOnduline from '../assets/logo-onduline.png';

function Brands() {
  const brands = [{
    image: logoImptek,
    imageAlt: t('Components.Brands.LOGO_BRAND_1_ALT'),
  }, {
    image: logoEternit,
    imageAlt: t('Components.Brands.LOGO_BRAND_2_ALT'),
  }, {
    image: logoPolyarq,
    imageAlt: t('Components.Brands.LOGO_BRAND_3_ALT'),
  }, {
    image: logoOnduline,
    imageAlt: t('Components.Brands.LOGO_BRAND_4_ALT'),
  }, {
    image: logoCalaminon,
    imageAlt: t('Components.Brands.LOGO_BRAND_5_ALT'),
  }];

  return (
    <div className="bg-primary-dark">
      <div className="container py-12">
        <div className="col-span-full lg:col-span-4 xl:col-span-3 xl:col-start-2 self-center text-center lg:text-left mobile-content-max-width">
          <h2 className="text-neutral-lightest">{t('Components.Brands.TITLE')}</h2>
        </div>
        <div className="col-span-full lg:col-span-8 xl:col-span-7 xl:col-end-12">
          <div className="max-w-sm lg:max-w-full mx-auto lg:mx-0 flex flex-wrap justify-evenly">
            {brands.map((brand) => (
              <img key={brand.imageAlt} src={brand.image} alt={brand.imageAlt} className="w-36 lg:w-50 xl:w-56 mt-8 lg:mt-0" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brands;
