import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import es from './es/common.json';

const i18n = i18next.use(LanguageDetector).init({
  defaultValue: 'TRANSLATION_NOT_FOUND',
  interpolation: {
    escape: false,
    escapeValue: false,
  },

  fallbackLng: 'es',
  defaultNS: 'common',

  resources: {
    es: { common: es },
  },
});

export const t = i18n.getFixedT();
export default i18n;
