import { t } from '@i18n';
import Card from './Card';
import categoryImage1 from '../assets/roof-category.png';
import categoryImage2 from '../assets/prefabricated-category.png';
import categoryImage3 from '../assets/finishes-category.png';
import categoryImage4 from '../assets/ecofriendly-category.png';

function Categories() {
  const categories = [{
    title: t('Components.Categories.CATEGORY_1_TITLE'),
    abstract: t('Components.Categories.CATEGORY_1_DESCRIPTION'),
    image: categoryImage1,
    imageAlt: t('Components.Services.CATEGORY_1_IMAGE_ALT'),
    link: '/matservi-catalogo-techos.pdf',
  }, {
    title: t('Components.Categories.CATEGORY_2_TITLE'),
    abstract: t('Components.Categories.CATEGORY_2_DESCRIPTION'),
    image: categoryImage2,
    imageAlt: t('Components.Services.CATEGORY_2_IMAGE_ALT'),
    link: '/matservi-catalogo-prefabricados.pdf',
  }, {
    title: t('Components.Categories.CATEGORY_3_TITLE'),
    abstract: t('Components.Categories.CATEGORY_3_DESCRIPTION'),
    image: categoryImage3,
    imageAlt: t('Components.Services.CATEGORY_3_IMAGE_ALT'),
    link: '/matservi-catalogo-acabados.pdf',
  }, {
    title: t('Components.Categories.CATEGORY_4_TITLE'),
    abstract: t('Components.Categories.CATEGORY_4_DESCRIPTION'),
    image: categoryImage4,
    imageAlt: t('Components.Services.CATEGORY_4_IMAGE_ALT'),
    link: '/matservi-catalogo-linea-ecoamigable.pdf',
  }];

  return (
    <div className="container py-12 lg:py-20" id="products">
      <div className="col-span-full text-center mb-6 lg:mb-12">
        <h2 className="text-primary-darkest">{t('Components.Categories.TITLE')}</h2>
      </div>
      {categories.map((category, i) => (
        <div
          key={`${category.title}-div`}
          className={`mb-8 col-span-full lg:col-span-6 xl:col-span-5 ${i % 2 ? 'lg:ml-2 xl:col-end-12' : 'lg:mr-2 xl:col-start-2'}`}
        >
          <Card
            title={category.title}
            abstract={category.abstract}
            image={category.image}
            imageAlt={category.imageAlt}
            link={category.link}
          />
        </div>
      ))}
    </div>
  );
}

export default Categories;
