import { useCallback } from 'react';
import { t } from '@i18n';
import PropTypes from 'prop-types';
import Button from './Button';

function Card({ title, image, imageAlt, abstract, link, className }) {
  const handleClick = useCallback(() => {
    window.rudderanalytics.track(
      'clicked - category-products-file', {
        category_name: title,
        link,
      },
    );

    window.open(link, '_blank').focus();
  }, [title, link]);

  return (
    <div onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex={0} className={className}>
      <div className="border border-neutral-light rounded p-4 lg:p-6 mobile-content-max-width transition duration-300 hover:shadow-md">
        <div className="flex items-center">
          <img src={image} alt={imageAlt} className="w-28 lg:w-48" />
          <div className="ml-4 lg:ml-6">
            <h3 className="text-neutral-darkest mb-2">{ title }</h3>
            <Button type="terciary" text={t('Components.Card.CALL_TO_ACTION')} icon="arrow-forward" />
          </div>
        </div>
        <div className="h-0.25 bg-neutral-light my-4" />
        <p className="text-neutral-dark font-normal">{ abstract }</p>
      </div>
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  abstract: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
};

Card.defaultProps = {
  title: 'Default title',
  imageAlt: 'Default alt',
  abstract: 'Default abstract',
  link: '#',
};

export default Card;
