import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from './Icon';

function Button({ type, text, icon, className }) {
  return (
    <button className={classNames(`btn-${type} transition duration-300`, { 'has-icon': icon }, className)}>
      { text }
      { icon && <span className="ml-2"><Icon name={icon} /></span> }
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
};

Button.defaultProps = {
  type: 'primary',
  text: 'Default content',
};

export default Button;
