import { t } from '@i18n';

function Hero() {
  return (
    <div className="bg-no-repeat bg-top bg-cover bg-hero-mobile lg:bg-hero-desktop">
      <div className="bg-neutral-darkest bg-opacity-60 pt-20 lg:pt-24">
        <div className="container h-80 lg:h-96 xl:h-128 text-neutral-lightest">
          <div className="col-span-full lg:col-span-5 xl:col-span-3 xl:col-start-2 self-center">
            <div className="mobile-content-max-width">
              <h1 className="mb-4">{t('Components.Hero.TITLE')}</h1>
              <p className="lg:text-lg">{t('Components.Hero.PARAGRAPH')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
