import { t } from '@i18n';
import ItemList from './ItemList';
import logo from '../assets/logo.png';
import {
  GOOGLE_MAPS_LINK,
} from '../variables';

function Footer() {
  return (
    <div className="container pt-12 pb-6 text-primary-darkest">
      <div className="col-start-1 col-span-3 mb-12 lg:mb-0">
        <img src={logo} alt={t('Components.Footer.ALT_LOGO')} className="max-h-14" />
      </div>
      <div className="col-span-full lg:col-span-4 lg:col-start-5 mb-12 lg:mb-0">
        <p className="subtitle-M">{t('Components.Footer.SECTION_TITLE_1')}</p>
        <a href={GOOGLE_MAPS_LINK} target="_blank" rel="noreferrer" className="font-normal">
          <ItemList icon="pin" text={t('Components.Footer.SECTION_1_DESCRIPTION_1')} />
        </a>
        <ItemList icon="phone" text={t('Components.Footer.SECTION_1_DESCRIPTION_2')} />
      </div>
      <div className="col-span-full lg:col-span-4 mb-12 lg:mb-0">
        <p className="subtitle-M">{t('Components.Footer.SECTION_TITLE_2')}</p>
        <ItemList icon="checkmark-circle-2" text={t('Components.Footer.SECTION_2_DESCRIPTION_1')} />
        <ItemList icon="checkmark-circle-2" text={t('Components.Footer.SECTION_2_DESCRIPTION_2')} />
      </div>
      <div className="col-span-full">
        <div className="h-0.25 bg-neutral-light mb-4 lg:mt-12" />
        <p className="caption">{t('Components.Footer.ALL_RIGHTS_RESERVED')}</p>
      </div>
    </div>
  );
}

export default Footer;
