import { useState } from 'react';
import { t } from '@i18n';
import Input from './Input';
import Button from './Button';
import whatsappIcon from '../assets/whatsapp-icon-colored.svg';
import {
  WHATSAPP_NUMBER,
  DEFAULT_WHATSAPP_MESSAGE,
} from '../variables';

function Contact() {
  const [message, updateMessage] = useState('');

  return (
    <div className="bg-no-repeat bg-center bg-cover lg:bg-contact">
      <div className="bg-primary-darkest lg:bg-neutral-lightest lg:bg-opacity-10 lg:py-20 xl:py-28">
        <div className="container">
          <div className="col-span-full text-white text-center">
            <div className="bg-primary-darkest max-w-sm mx-auto py-12 lg:px-8">
              <img src={whatsappIcon} alt="Ícono Whatsapp" className="w-12 w-16 mb-8 mx-auto" />
              <h2 className="mb-6 text-neutral-lightest">{t('Components.Contact.TITLE')}</h2>
              <p className="mb-6 text-neutral-lightest lg:text-lg">{t('Components.Contact.PARAGRAPH')}</p>
              <Input placeholder={t('Components.Contact.PLACEHOLDER')} onChange={updateMessage} />
              <a
                href={`https://wa.me/${WHATSAPP_NUMBER}/?text=${message || DEFAULT_WHATSAPP_MESSAGE}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button text={t('Components.Contact.CALL_TO_ACTION')} icon="arrow-forward" className="w-full mt-8" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
