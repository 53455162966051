import { t } from '@i18n';
import serviceImage1 from '../assets/product-advisory-service.png';
import serviceImage2 from '../assets/project-evaluation-service.png';
import serviceImage3 from '../assets/product-installation-service.png';

function Services() {
  const services = [{
    title: t('Components.Services.SERVICE_1_TITLE'),
    abstract: t('Components.Services.SERVICE_1_DESCRIPTION'),
    image: serviceImage1,
    imageAlt: t('Components.Services.SERVICE_1_IMAGE_ALT'),
  }, {
    title: t('Components.Services.SERVICE_2_TITLE'),
    abstract: t('Components.Services.SERVICE_2_DESCRIPTION'),
    image: serviceImage2,
    imageAlt: t('Components.Services.SERVICE_2_IMAGE_ALT'),
  }, {
    title: t('Components.Services.SERVICE_3_TITLE'),
    abstract: t('Components.Services.SERVICE_3_DESCRIPTION'),
    image: serviceImage3,
    imageAlt: t('Components.Services.SERVICE_3_IMAGE_ALT'),
  }];

  return (
    <div className="container py-12 lg:py-20" id="services">
      <div className="col-span-full text-center mobile-content-max-width">
        <h2 className="text-primary-darkest">{t('Components.Services.TITLE')}</h2>
        <p className="mt-6 text-neutral-dark lg:text-lg lg:mb-2">{t('Components.Services.PARAGRAPH')}</p>
      </div>
      <div className="col-span-full mobile-content-max-width lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4">
        {services.map((service) => (
          <div key={service.title} className="border border-neutral-light rounded p-4 lg:p-6 mt-6">
            <div className="flex items-center">
              <img src={service.image} alt={service.imageAlt} className="w-28 lg:w-40" />
              <div className="w-full ml-4 lg:ml-12">
                <h3 className="text-neutral-darkest lg:mb-4">{ service.title }</h3>
                <p className="text-neutral-dark hidden lg:block">{ service.abstract }</p>
              </div>
            </div>
            <div className="h-0.25 bg-neutral-light my-4 lg:hidden" />
            <p className="text-neutral-dark lg:hidden">{ service.abstract }</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
